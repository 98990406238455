//import WOW from "wow.js";

// var wrapper = document.querySelector("[data-m-sitewrapper]");
// window.wow = new WOW({
//   live: false,
//   scrollContainer: wrapper,
// });

// window.wow.init();

function createObserver() {
  let observer;

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: buildThresholdList(),
  };

  observer = new IntersectionObserver(handleIntersect, options);

  Array.from(document.querySelectorAll(".wow")).forEach((element) => {
    element.style.opacity = 0;
    element.style.transform = "translateY(-100px)";
    observer.observe(element);
  });
}

function buildThresholdList() {
  let thresholds = [];
  let numSteps = 50;

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

function handleIntersect(entries, observer) {
  entries.forEach((entry) => {
    const is_done = entry.target.style.opacity == 1;
    if (!is_done) {
      entry.target.style.opacity = entry.intersectionRatio;
      entry.target.style.transform = `translateY(${
        100 - (entry.intersectionRatio * 100).toFixed(0)
      }px)`;
    }

    const is_scroll_down =
      entry.boundingClientRect.y > entry.target.dataset.previousY;
    const is_ratio_decreasing =
      entry.intersectionRatio < entry.target.dataset.previousRatio;
    if (is_ratio_decreasing && is_scroll_down) {
      entry.target.style.opacity = entry.intersectionRatio;
      entry.target.style.transform = `translateY(-${
        100 - (entry.intersectionRatio * 100).toFixed(0)
      }px)`;
    }

    entry.target.dataset.previousY = entry.boundingClientRect.y;
    entry.target.dataset.previousRatio = entry.intersectionRatio;
  });
}

document.addEventListener("mousemove", function (event) {
  var elements = Array.from(document.getElementsByClassName("teaser__shadow"));
  elements = elements.concat(
    Array.from(document.getElementsByClassName("headline__shadow"))
  );

  var maxPixelsX = 20;
  var percentageX = event.x / window.innerWidth;
  var moveX = maxPixelsX * percentageX;
  if (percentageX < 50) {
    moveX = moveX - maxPixelsX;
  }

  var maxPixelsY = 20;
  var percentageY = event.y / window.innerHeight;
  var moveY = maxPixelsY * percentageY;
  if (percentageY < 50) {
    moveY = moveY - maxPixelsY;
  }

  elements.forEach((element) => {
    element.style.transform = "translate(" + moveX + "px, " + moveY + "px)";
  });
});

document.onreadystatechange = function () {
  createObserver();

  var accordion_rows = Array.from(
    document.getElementsByClassName("accordion__row-inner")
  );
  accordion_rows.forEach(function (element) {
    element.parentElement.querySelector(".accordion__body").style.maxHeight =
      "0px";

    element.onclick = function (event) {
      var row = event.currentTarget.parentElement;
      var body = row.querySelector(".accordion__body");
      if (row.classList.contains("accordion__row--active")) {
        window.setTimeout(() => {
          body.style.maxHeight = "0px";
        }, 300);
      } else {
        body.style.maxHeight = body.scrollHeight + "px";
      }
      row.classList.toggle("accordion__row--active");
    };
  });
};
